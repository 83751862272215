body {
    margin: 0;
    padding: 0;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

a {
    text-decoration: none;
    color: #0295D6;
}

.link-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 20px;
}

.link-list > span {
    width: 30%;
}

li {
    list-style-type: square;
}